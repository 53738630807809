import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import { FooterAlternate1 } from "../../../components/footer"
import SEO from "../../../components/seo"

const IndexPage = () => (
    <Layout>
      <SEO title="Measuring pipeline drives the wrong behaviours!" />

      <nav className="share-bar">
    <ul>
        <li className="linkedin">
            <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/&amp;title=Measuring%20pipeline%20drives%20the%20wrong%20behaviours%21&amp;summary=Measuring%20pipeline%20drives%20the%20wrong%20behaviours%21&amp;source=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">LinkedIn</span>
                <span className="icon">
                    <svg width="23" height="23">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-linkedin-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="twitter">
            <a href="https://twitter.com/intent/tweet/?text=Measuring%20pipeline%20drives%20the%20wrong%20behaviours%21&amp;url=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Twitter</span>
                <span className="icon">
                    <svg width="23" height="23">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-twitter-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="googleplus">
            <a href="https://plus.google.com/share?url=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Google+</span>
                <span className="icon">
                    <svg width="26" height="26">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-googleplus-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="facebook">
            <a href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Facebook</span>
                <span className="icon">
                    <svg width="22" height="22">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-facebook-badge"></use>
                    </svg>
                </span>
            </a>
        </li>
        <li className="email">
            <Link to="knowledge-centre/insights/mailto:?subject=Measuring%20pipeline%20drives%20the%20wrong%20behaviours%21&amp;body=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Email</span>
                <span className="icon">
                    <svg width="20" height="20">
                        <use href="../../../static/images/svg/symbol-defs.svg#social-email-badge"></use>
                    </svg>
                </span>
            </Link>
        </li>
    </ul>
</nav>

<article className="article">

    

<header className="header header--article article__header header--video" id="page-header">
    <div className="header__image" data-video-uri="https://www.youtube.com/embed/6BqxSNtCBQ0?autoplay=1&amp;modestbranding=0&amp;rel=0&amp;showinfo=0" data-video-target="#page-header .embed-responsive" data-video-parent="#page-header">
        <div className="header__bgimage" style={{backgroundImage: "url(../../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-1162x655.jpg)"}}></div>
        <div className="header__video">
            <div className="embed-responsive embed-responsive-16by9"></div>
        </div>
    </div>
    <div className="header__content">
        <div className="container">
            <div className="row">
                <div className="header__inner col-md-7 col-lg-offset-1">
                    <Link to="knowledge-centre/insights/" className="header__back back-link">
                        <span className="sr-only">Back to all articles</span>
                        <i className="icon"></i>
                    </Link>
                    <div className="header__title">
                        <h3>B2B Sales</h3>
                        <h1>Measuring pipeline drives the wrong behaviours!</h1>
                    </div>
                    <ul className="post-meta-list">
                        <li>by Graham Hawkins</li>
                        <li>12 September 2016</li>
                        <li>5:34</li>
                    </ul>
                    <div className="header__icon play-icon">
                        <i className="icon"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>


<div className="container" id="article-content">
    <div className="row">
        <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7 wysiwyg">
            
<h3>Sales people are measured and managed by metrics that encourage and foster the wrong behaviours. Focusing on &#39;pipeline size&#39; is a big mistake.</h3>
<div className="rich-text"><p>For years we have emphasized that it is vital that we measure our sales pipeline or sales funnel.  The excepted wisdom for pipeline management was based around putting enough leads in one end, measuring the quantity of opps at each stage as they flow through, and then forecasting and realizing the orders (and revenue) that flow out the other end. Surely this cannot be wrong! <br/></p><p>In this interview by John Smibert, Graham Hawkins slays a sacred cow of sales by suggesting that we should not measure our pipeline. <br/></p></div>



        </div>
            
        <aside className="article__sidebar col-sm-12 col-md-4 col-lg-offset-1 col-lg-3">
            <div className="article__sidebar-wrap" id="article-sidebar">
                <div className="card card--profile">
                        
<div className="card__header">
    <div className="card__image">
        <img alt="Mentor Thumb Graham Hawkins" className="img-responsive" height="165" src="../../../media/images/Mentor-Thumb-Graham-Hawkins.2e16d0ba.fill-165x165.jpg" width="165"/>
    </div>
    <div className="card__title">
        <h4>Graham Hawkins</h4>
        <p>SalesTribe Mentor</p>
        <Link to="solutions/find-a-mentor/graham-hawkins" className="btn btn-link hidden-md hidden-lg">View full profile</Link>
    </div>
</div>
<div className="rich-text"><p>With more than twenty-eight years of business experience in
executive B2B sales and sales leadership roles, Graham is a highly experienced
and versatile business executive with proven strengths in strategic business
development, go-to-market planning and sales and marketing.</p><p><br/></p><p><br/></p></div>
<Link to="solutions/find-a-mentor/graham-hawkins" className="btn btn-link hidden-xs hidden-sm">View full profile</Link>

                </div>
                <nav className="social-nav">
    <h4>Share</h4>
    <ul>
        <li className="linkedin">
            <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/&amp;title=Measuring%20pipeline%20drives%20the%20wrong%20behaviours%21&amp;summary=Measuring%20pipeline%20drives%20the%20wrong%20behaviours%21&amp;source=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">LinkedIn</span>
                <span className="icon icon-linkedin"></span>
                
            </a>
        </li>
        <li className="twitter">
            <a href="https://twitter.com/intent/tweet/?text=Measuring%20pipeline%20drives%20the%20wrong%20behaviours%21&amp;url=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Twitter</span>
                <span className="icon icon-twitter"></span>
                
            </a>
        </li>
        <li className="googleplus">
            <a href="https://plus.google.com/share?url=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Google+</span>
                <span className="icon icon-google-plus"></span>
                
            </a>
        </li>
        <li className="facebook">
            <a href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Facebook</span>
                <span className="icon icon-facebook-f"></span>
                
            </a>
        </li>
        <li className="email">
            <Link to="knowledge-centre/insights/mailto:?subject=Measuring%20pipeline%20drives%20the%20wrong%20behaviours%21&amp;body=https%3A//salestribe.com/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate/" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Email</span>
                <span className="icon icon-envelope"></span>
                
            </Link>
        </li>
    </ul>
</nav>

            </div>
        </aside>
            
    </div>
</div>
</article>

<div className="block block--grey related-items">
    <div className="related-items__wrap">
        <div className="container">
            <div className="row" data-tablet-slick>
                
        <article className="related-items__item col-sm-6 col-md-4">
            <Link to="knowledge-centre/insights/sales-training-complete-waste-money-and-time-part-2" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-connect-coffee-chat.jpg" className="card__image" height="404" src="../../../media/images/article-connect-coffee-chat_xr8wJT.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales Training’ is a waste of money and time (Part 2)</strong></h3>
                    </header>
                    <p>We must stop teaching sales people how to ‘push’ buyers, and instead start training and coaching them on how to become highly specialised teachers.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="related-items__item col-sm-6 col-md-4">
            <Link to="knowledge-centre/insights/sales-training-waste-time-and-money-part-1" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-connect-coffee-chat.jpg" className="card__image" height="404" src="../../../media/images/article-connect-coffee-chat_xr8wJT.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>‘Sales Training’ is a waste of time and money (Part 1)</strong></h3>
                    </header>
                    <p>When you teach sales people how to ‘push’ buyers through a sales playbook you are teaching them how to destroy your business reputation.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="related-items__item col-sm-6 col-md-4">
            <Link to="knowledge-centre/insights/b2b-sales-has-changed-about-180-degrees" className="card card--article">
                <div className="card__thumb">
                    <img alt="Mentor Large Graham Hawkins" className="card__image" height="404" src="../../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">04:28</span>
                    <span className="card__icon play-icon">
                        <i className="icon"></i>
                    </span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>B2B Sales has changed about 180 Degrees!</strong></h3>
                    </header>
                    <p>B2B sales has now changed so dramatically that it&#39;s a &quot;profession&quot; that is barely recognisable to the role that both Tony Hughes and Graham Hawkins began about 30 years ago - respectively.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>

            </div>
            <div className="load-more">
                <Link to="knowledge-centre/insights/" className="btn btn-link">Browse more insights</Link>
            </div>
        </div>
    </div>
</div>
      
      <FooterAlternate1 />

    </Layout>
)

export default IndexPage 